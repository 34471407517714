import { createStore } from 'vuex'
import axiosInstance from '../axiosInstance'

const store = createStore({
  state: {
    user: null,
    accessToken: localStorage.getItem('access_token') || '',
    refreshToken: localStorage.getItem('refresh_token') || ''
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setAccessToken (state, token) {
      state.accessToken = token
      localStorage.setItem('access_token', token)
    },
    setRefreshToken (state, token) {
      state.refreshToken = token
      localStorage.setItem('refresh_token', token)
    },
    clearAuthData (state) {
      state.user = null
      state.accessToken = ''
      state.refreshToken = ''
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
    }
  },
  actions: {
    async loginAction ({ commit }, credentials) {
      const response = await axiosInstance.post('token/', credentials)
      commit('setAccessToken', response.data.access)
      commit('setRefreshToken', response.data.refresh)
      commit('setUser', response.data.user)
    },
    async fetchUser ({ commit }) {
      try {
        const response = await axiosInstance.get('user/', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        })
        commit('setUser', response.data)
      } catch (error) {
        console.error('An error occurred:', error)
      }
    },
    async logoutAction ({ commit }) {
      try {
        await axiosInstance.post('logout/', {
          refresh_token: this.state.refreshToken
        })
      } catch (error) {
        console.error('Error during logout:', error)
      } finally {
        commit('clearAuthData')
      }
    }
  },
  getters: {
    loggedIn (state) {
      return !!state.accessToken
    }
  }
})

export default store
