import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.getters.loggedIn) {
      next({ name: 'default' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
