import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVueNext from 'bootstrap-vue-next'

import vClickOutside from 'click-outside-vue3'
import VueApexCharts from 'vue3-apexcharts'
import { vMaska } from 'maska'
import VueFeather from 'vue-feather'
import VueSocketIO from 'vue-3-socket.io'
import SocketIO from 'socket.io-client'

import '@/assets/scss/config/material/app.scss'
import '@/assets/scss/mermaid.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'

const socketConnectionBranch = SocketIO('http://localhost:8000/ws/branches/')

createApp(App)
  .use(new VueSocketIO({
    debug: true,
    connection: socketConnectionBranch
  }))
  .use(router)
  .use(store)
  .use(BootstrapVueNext)
  .use(VueApexCharts)
  .component(VueFeather.type, VueFeather)
  .directive('maska', vMaska)
  .use(vClickOutside)
  .mount('#app')
